.App {
  display: grid;
  grid-template-columns: 98px 1fr;
  height: 100vh;
}

.FullParentWidth {
    width: 100%;
    /* border: 1px solid red; */
}
.CenterContentsHorizontal {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 1px solid yellow; */
}
.CenterContentsLeft {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* align-items: center; */
    /* border: 1px solid yellow; */
}

.HorizontalFlex {
    display: flex;
    flex-direction: row;
}
.VerticalFlex {
    display: flex;
    flex-direction: column;
}
.CenterContents {
    justify-content: center;
}
.CenterContentsVerticalInHorizontalFlex {
    align-items: center;
}
.CenterContentsHorizontalInFlex {
    align-items: center;
}
.CenterContentsVertical {
    justify-content: center;
}

.CenterContentsVertical {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.CenterContentsCompletely {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.PendingItemContainer {
    padding-bottom: 30px;
}
.PendingInformationContainer {
    /* border: 1px solid white; */
    padding-left: 15px;

}
.PendingProfilePhotoContainer {
    width: 65px;
    height: 65px;
    margin-right: 10px;
    /* border: 1px solid white; */
}
.PendingProfileImage {
    width: 65px;
    height: 65px;
    object-fit: cover;
    border-radius: 50%;
}
.PendingNameContainer {
    /* border: 1px solid white; */
    padding-bottom: 4px;
}
.DashboardVerticalSection {
    padding-top: 50px;
    padding-bottom: 20px;
}

.PendingProjectNameText {
    /* font-family: SF-Pro-Display-Regular; */
    /* border: 1px solid yellow; */
    font-style: normal;
    margin: 0;
    font-weight: bold;
    text-align: left;
    font-size: 20px;
    letter-spacing: 0.4px;
    cursor: pointer;
    /* color: rgba(255, 255, 255, 0.87); */
}
.TitleText {
     /* font-family: SF-Pro-Display-Regular; */
    /* border: 1px solid yellow; */
    font-style: normal;
    margin: 0;
    /* font-weight: bold; */
    text-align: left;
    font-size: 22px;
    letter-spacing: 0.4px;
    cursor: pointer;
    margin-right: 10px;
    /* color: rgba(255, 255, 255, 0.87); */
}
.TitleTextValue {
    /* font-family: SF-Pro-Display-Regular; */
   /* border: 1px solid yellow; */
   font-style: normal;
   margin: 0;
   font-weight: bold;
   text-align: left;
   font-size: 22px;
   letter-spacing: 0.4px;
   cursor: pointer;
   margin-right: 10px;
   /* color: rgba(255, 255, 255, 0.87); */
}

.LargeNum {
    margin: 0px;
    font-style: bold;
    font-size: 50px;
    color: #8884d8;
}

.LargeNumHuge {
    margin: 0px;
    font-style: bold;
    font-size: 120px;
    color: #8884d8;
}

.PendingProjectNameText:hover {
    color: #2B1C50;
    transition: 0.3s all ease;
}
.PendingProjectInformationItem {
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* border: 1px solid white; */
    /* min-width: 200px */
}
.PendingProjectsList {
    width: 600px;
    height: 450px;
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.NumberSection {
    margin-left: 15px;
    margin-right: 15px;
}

.LargeTextSection {
    width: 280px;
    height: 140px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.LargeTextSectionHuge {
    width: 600px;
    height: 450px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.app-title-text {
    font-style: normal;
    margin: 0;
    font-weight: normal;
    padding-top: 4px;
    font-size: 35px;
    letter-spacing: 0.4px;
    text-align: left;
    color: #745DDE;
}

.PendingDescriptionText {
    font-style: normal;
    margin: 0;
    font-weight: normal;
    text-align: left;
    font-size: 16px;
    letter-spacing: 0.35px;
    /* color: rgba(255, 255, 255, 0.8); */
}

.WalletAddressContainerDetails {
    display: flex;
    flex-direction: row;
    width: fit-content;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid gray;
}

.WalletAddressLink {
    /* font-family: SF-Pro-Display-Regular; */
    /* border: 1px solid yellow; */
    font-style: normal;
    padding-top: 2px;
    margin: 0;
    /* font-weight: bold; */
    text-align: left;
    font-size: 13px;
    letter-spacing: 0.4px;
    text-decoration: none;
    padding-left: 7px;
    /* color: rgba(255, 255, 255, 0.7); */
}

.WalletAddressContainer {
    display: flex;
    flex-direction: row;
    width: fit-content;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 10px;
    border: 0.5px solid #0D0C0D;
}
.SocialIconItemContainer {
    padding-left: 10px;
}

.PendingProjectActionsDiv {
    width: 80%;
    /* border: 1px solid white; */
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 15px;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.4);
}
.PendingProjectContainer {
    width: 80%;
    justify-content: space-between;
    /* border: 1px solid white; */
}
.PendingProjectMainContainer {
    padding-top: 20px;
}

.SignUpFormSubmitButton {
    background-color: #565ADD;
    width: 100%;
    border: none;
    font-family: SF-Pro-Display-Bold;
    /* border: 1px solid yellow; */
    font-style: normal;
    margin: 0;
    font-weight: normal;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    font-size: 18px;
    letter-spacing: 0.4px;
    border-radius: 6px;
    color: rgba(255, 255, 255, 1);
}
.SignUpFormSubmitButton:hover {
    cursor: pointer;
}
.SignInButtonText {
    margin: 0;
}
.SignUpForm {
    margin-top: 20px;
    display: flex;
    width: 350px;
    flex-direction: column;
}

.InputSignUp {
    height: 40px;
    /* border: none; */
    padding-left: 20px;
    margin-bottom: 15px;
    font-size: 15px;
    letter-spacing: 0.4px;
    border-radius: 6px;
    /* border: 1px solid green; */
}
.SignUpMethodContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ReviewErrorText {
    font-style: normal;
    margin: 0;
    padding-top: 5px;
    text-align: left;
    font-size: 15px;
    letter-spacing: 0.4px;
    color: #CF6679;
    margin-bottom: 10px;
}

.AddingProjectHelperText {
    font-style: normal;
    margin: 0;
    margin-bottom: 2px;
    font-weight: bold;
    margin-left: 20px;
    text-align: center;
    font-size: 22px;
    letter-spacing: 0.4px;
}

.SubmitLoadingContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    z-index: 100;
    /* border: 1px solid blue; */
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
}
.ModalOverLay {
    background-color: black;
    width: 100vw;
    height: 100vh;
}
.JSONView {
    width: 80%;
    /* background-color: black; */
}

.btn-purple {
    background-color: #745dde; /* change this to any shade of purple you want */
    color: white;
    border: 1px solid transparent;   
}
.btn-purple:hover {
    background-color: #5f4cc3; /* change this to any shade of purple you want */
    color: white;
    border: 1px solid transparent;
    transition: 0.3s all ease;
}
.btn-purple-outline {
    background-color: transparent;
    color: #745dde; /* change this to any shade of purple you want */
    border: 1px solid #745dde;   
}
.btn-purple-outline:hover {
    background-color: #745dde; /* change this to any shade of purple you want */
    color: white;
    border: 1px solid transparent;  
    transition: 0.3s all ease; 
}

.switch-toggle-container {
    display: flex;
    justify-content: center;
}

.form-select {
    max-width: 300px;
}

iframe {
    position: relative;
}